import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { FaBars, FaTasks, FaProjectDiagram, FaCheckSquare } from 'react-icons/fa';
import './styles/taskmanagerHeader.css';

function TaskmanagerHeader({ toggleMenu }) {
  const location = useLocation();
  
  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <header className="taskmanager-header">
      <div className="header-left">
        <button className="menu-button" onClick={toggleMenu}>
          <FaBars />
        </button>
        <h1>Tasks</h1>
      </div>
      
      <nav className="taskmanager-nav">
        <NavLink 
          to="/tasks/projects" 
          className={`nav-link ${isActive('/tasks/projects') ? 'active' : ''}`}
        >
          <FaProjectDiagram />
          <span>Projekter</span>
        </NavLink>
        
        <NavLink 
          to="/tasks/tasks" 
          className={`nav-link ${isActive('/tasks/tasks') ? 'active' : ''}`}
        >
          <FaTasks />
          <span>Opgaver</span>
        </NavLink>
        
        <NavLink 
          to="/tasks/todo" 
          className={`nav-link ${isActive('/tasks/todo') ? 'active' : ''}`}
        >
          <FaCheckSquare />
          <span>Min To do</span>
        </NavLink>
      </nav>
    </header>
  );
}

export default TaskmanagerHeader;
