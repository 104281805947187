import React from 'react';
import { FaBars, FaUsers, FaUserFriends, FaUserPlus } from 'react-icons/fa';
import './styles/crmHeader.css';

function CRMHeader({ onToggleMenu, activeFilter, onFilterChange, onAddCustomer }) {
  return (
    <div className="crm-header-container">
      <div className="header-left">
        <button className="menu-toggle" onClick={onToggleMenu}>
          <FaBars />
        </button>
        <div className="filter-buttons">
          <button 
            className={`filter-btn ${activeFilter === 'all' ? 'active' : ''}`}
            onClick={() => onFilterChange('all')}
          >
            <FaUsers style={{ marginRight: '8px' }} />
            Alle kunder
          </button>
          <button 
            className={`filter-btn ${activeFilter === 'mine' ? 'active' : ''}`}
            onClick={() => onFilterChange('mine')}
          >
            <FaUserFriends style={{ marginRight: '8px' }} />
            Mine kunder
          </button>
        </div>
      </div>
      <button className="add-customer-btn" onClick={onAddCustomer}>
        <FaUserPlus />
        Tilføj ny kunde
      </button>
    </div>
  );
}

export default CRMHeader;
