import React from 'react';
import './styles/dashboardMain.css';

function Dashboard() {
  return (
    <div className="dashboard-container">
      <h1>Dashboard</h1>
      <div className="dashboard-content">
        <p>Velkommen til Parla Hub</p>
        {/* Her kan du tilføje mere dashboard indhold senere */}
      </div>
    </div>
  );
}

export default Dashboard; 