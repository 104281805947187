import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { FaEnvelope, FaPhone, FaGlobe, FaMoneyBillWave } from 'react-icons/fa';
import './styles/customerCard.css';

function CustomerCard({ customer, statusColor, onClick }) {
  const [accountOwner, setAccountOwner] = useState(null);

  useEffect(() => {
    if (customer.accountOwner) {
      fetchAccountOwner();
    }
  }, [customer.accountOwner]);

  const fetchAccountOwner = async () => {
    try {
      const userDoc = await getDoc(doc(db, 'users', customer.accountOwner));
      if (userDoc.exists()) {
        setAccountOwner(userDoc.data());
      }
    } catch (error) {
      console.error('Fejl ved hentning af account owner:', error);
    }
  };

  // Formatter til DKK
  const formatCurrency = (amount) => {
    if (!amount) return '';
    return new Intl.NumberFormat('da-DK', {
      style: 'currency',
      currency: 'DKK',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(amount);
  };

  return (
    <div className="customer-card" onClick={onClick}>
      <div className="status-indicator" style={{ background: statusColor }} />
      
      <div className="card-header">
        <h3>{customer.name}</h3>
        {accountOwner && (
          <div className="account-owner">
            <span className="owner-label">Ejer:</span>
            <span className="owner-name">{accountOwner.name || accountOwner.email}</span>
          </div>
        )}
      </div>

      <div className="card-content">
        {customer.email && (
          <div className="info-row">
            <FaEnvelope className="info-icon" />
            <span>{customer.email}</span>
          </div>
        )}
        
        {customer.phone && (
          <div className="info-row">
            <FaPhone className="info-icon" />
            <span>{customer.phone}</span>
          </div>
        )}

        {customer.websiteUrl && (
          <div className="info-row">
            <FaGlobe className="info-icon" />
            <span className="truncate">{customer.websiteUrl}</span>
          </div>
        )}

        {customer.estimatedValue && (
          <div className="info-row highlight">
            <FaMoneyBillWave className="info-icon" />
            <span>{formatCurrency(customer.estimatedValue)}</span>
          </div>
        )}
      </div>

      <div className="card-footer">
        {customer.products && customer.products.length > 0 && (
          <div className="tags-container">
            {customer.products.map(product => (
              <span key={product} className="tag product-tag">
                {PRODUCTS.find(p => p.id === product)?.label || product}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

// Tilføj PRODUCTS konstant her så vi kan vise labels i stedet for IDs
const PRODUCTS = [
  { id: 'website', label: 'Hjemmeside' },
  { id: 'video', label: 'Video' },
  { id: 'marketing', label: 'Marketing' },
  { id: 'automation', label: 'Automatisering' }
];

export default CustomerCard;
