import React, { useState, useEffect } from 'react';
import { collection, query, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import TaskmanagerHeader from '../taskmanagerHeader';
import '../styles/projectsMain.css';

const STATUS_TYPES = {
  todo: {
    label: 'To do',
    color: '#6b7280' // Grå
  },
  doing: {
    label: 'Doing',
    color: '#2563eb' // Blå
  },
  pause: {
    label: 'Pause',
    color: '#9333ea' // Lilla
  },
  done: {
    label: 'Done',
    color: '#22c55e' // Grøn
  }
};

function ProjectsMain({ toggleMenu }) {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(() => {
    const q = query(collection(db, 'projects'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const projectsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProjects(projectsData);
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="projects-main">
      <TaskmanagerHeader toggleMenu={toggleMenu} />
      
      <div className="projects-content">
        <div className="projects-columns">
          {Object.entries(STATUS_TYPES).map(([status, { label, color }]) => (
            <div 
              key={status} 
              className="status-column"
              style={{ '--status-color': color }}
            >
              <div className="column-header">
                <h3>{label}</h3>
                <div className="project-count">
                  {projects.filter(project => project.status === status).length}
                </div>
              </div>
              
              <div className="column-content">
                {projects
                  .filter(project => project.status === status)
                  .map(project => (
                    <div 
                      key={project.id}
                      className="project-placeholder"
                      onClick={() => setSelectedProject(project)}
                    >
                      {project.name}
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProjectsMain;
