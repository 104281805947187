import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { FaBars } from 'react-icons/fa';
import './styles/profil.css';

function Profile({ onToggleMenu }) {
  const [name, setName] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [message, setMessage] = useState({ text: '', type: '' });

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
      if (userDoc.exists()) {
        setName(userDoc.data().name || '');
      }
    } catch (error) {
      console.error('Fejl ved hentning af profil:', error);
      setMessage({ text: 'Kunne ikke hente profildata', type: 'error' });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    setMessage({ text: '', type: '' });

    try {
      await setDoc(doc(db, 'users', auth.currentUser.uid), {
        name,
        email: auth.currentUser.email,
        updatedAt: new Date().toISOString()
      }, { merge: true });

      setMessage({ text: 'Profil opdateret!', type: 'success' });
      setIsEditing(false);
    } catch (error) {
      console.error('Fejl ved opdatering af profil:', error);
      setMessage({ text: 'Kunne ikke gemme ændringer', type: 'error' });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="profile-container">
      <div className="profile-header">
        <button className="menu-toggle" onClick={onToggleMenu}>
          <FaBars />
        </button>
        <h1>Min Profil</h1>
      </div>
      
      <div className="profile-content">
        {message.text && (
          <div className={`message ${message.type}`}>
            {message.text}
          </div>
        )}

        <div className="profile-section">
          <div className="profile-info">
            <div className="info-group">
              <label>Email</label>
              <p>{auth.currentUser?.email}</p>
            </div>

            <div className="info-group">
              <label>Navn</label>
              {isEditing ? (
                <form onSubmit={handleSubmit} className="edit-form">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Indtast dit navn"
                    required
                  />
                  <div className="button-group">
                    <button 
                      type="button" 
                      onClick={() => setIsEditing(false)}
                      className="cancel-button"
                      disabled={isSaving}
                    >
                      Annuller
                    </button>
                    <button 
                      type="submit"
                      className="save-button"
                      disabled={isSaving}
                    >
                      {isSaving ? 'Gemmer...' : 'Gem'}
                    </button>
                  </div>
                </form>
              ) : (
                <div className="display-name">
                  <p>{name || 'Ikke angivet'}</p>
                  <button 
                    onClick={() => setIsEditing(true)}
                    className="edit-button"
                  >
                    Rediger
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
