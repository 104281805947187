import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { auth } from './firebase';
import './App.css';
import Dashboard from './dashboard/dashboardMain';
import CRMMain from './crm/crmMain';
import Profile from './components/profil';
import Menu from './components/menu';
import Login from './components/login';
import ProjectsMain from './taskmanager/projects/projectsMain';

function PrivateRoute({ children }) {
  const user = auth.currentUser;
  
  if (!user) {
    return <Navigate to="/login" />;
  }
  
  return children;
}

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        {user && <Menu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />}
        <Routes>
          <Route 
            path="/login" 
            element={user ? <Navigate to="/" /> : <Login />} 
          />
          <Route 
            path="/" 
            element={user ? <Dashboard onToggleMenu={() => setIsMenuOpen(true)} /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/crm" 
            element={user ? <CRMMain onToggleMenu={() => setIsMenuOpen(true)} /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/profil" 
            element={user ? <Profile onToggleMenu={() => setIsMenuOpen(true)} /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/tasks/projects" 
            element={
              <PrivateRoute>
                <ProjectsMain toggleMenu={() => setIsMenuOpen(true)} />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/tasks/tasks" 
            element={
              <PrivateRoute>
                <div>Opgaver kommer snart</div>
              </PrivateRoute>
            } 
          />
          <Route 
            path="/tasks/todo" 
            element={
              <PrivateRoute>
                <div>Min To do kommer snart</div>
              </PrivateRoute>
            } 
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
