import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import './styles/menu.css';
import { FaHome, FaUsers, FaTasks, FaSignOutAlt } from 'react-icons/fa';

function Menu({ isOpen, onClose }) {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Logout fejl:', error);
    }
  };

  const menuItems = [
    {
      title: 'Dashboard',
      icon: <FaHome />,
      path: '/'
    },
    {
      title: 'Opgaver',
      icon: <FaTasks />,
      path: '/tasks/projects'
    },
    {
      title: 'CRM',
      icon: <FaUsers />,
      path: '/crm'
    }
  ];

  return (
    <>
      <div className={`menu-overlay ${isOpen ? 'active' : ''}`} onClick={onClose}></div>
      <nav className={`side-menu ${isOpen ? 'active' : ''}`}>
        <button className="close-menu" onClick={onClose}>&times;</button>
        <div className="menu-content">
          <ul>
            {menuItems.map((item, index) => (
              <li key={index}>
                <Link to={item.path} onClick={onClose}>
                  {item.icon} {item.title}
                </Link>
              </li>
            ))}
            <li><Link to="/profil" onClick={onClose}>Profil</Link></li>
            <li><button onClick={handleLogout} className="logout-button">Log ud</button></li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Menu;
