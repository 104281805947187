import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, doc, updateDoc } from 'firebase/firestore';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { db, auth } from '../firebase';
import { FaPlus, FaUserPlus } from 'react-icons/fa';
import CustomerCard from './customerCard';
import CustomerWindow from './customerWindow';
import CRMHeader from './crmHeader';
import './styles/crmMain.css';

const STATUS_TYPES = {
  lead: { 
    label: 'Lead', 
    color: '#ec4899',
    icon: '🎯'
  },
  contacted: { 
    label: 'Kontaktet', 
    color: '#eab308',
    icon: '📞'
  },
  qualified: { 
    label: 'Kvalificeret', 
    color: '#f97316',
    icon: '✓'
  },
  meeting: { 
    label: 'Møde', 
    color: '#2563eb',
    icon: '📅'
  },
  ongoing: { 
    label: 'Igangværende', 
    color: '#9333ea',
    icon: '⚡'
  },
  notInterested: { 
    label: 'Ikke interesseret', 
    color: '#6b7280',
    icon: '✕'
  },
  won: { 
    label: 'Vundet', 
    color: '#22c55e',
    icon: '🏆'
  }
};

function CRMMain({ onToggleMenu }) {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isNewCustomer, setIsNewCustomer] = useState(false);
  const [activeFilter, setActiveFilter] = useState('all');
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    // Lyt til auth state changes
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    const querySnapshot = await getDocs(collection(db, 'customers'));
    const customerList = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      status: doc.data().status || 'lead'
    }));
    setCustomers(customerList);
  };

  const handleAddCustomer = async () => {
    const newCustomer = {
      name: 'Ny kunde',
      email: '',
      phone: '',
      address: '',
      notes: '',
      status: 'lead',
      createdAt: new Date().toISOString()
    };

    const docRef = await addDoc(collection(db, 'customers'), newCustomer);
    const customerWithId = { ...newCustomer, id: docRef.id };
    
    setCustomers(prev => [...prev, customerWithId]);
    setSelectedCustomer(customerWithId);
    setIsNewCustomer(true);
  };

  const getCustomersByStatus = (status) => {
    let filteredCustomers = customers;
    
    // Filtrer først efter "Mine kunder" hvis det er valgt
    if (activeFilter === 'mine' && currentUser) {
      filteredCustomers = customers.filter(customer => 
        customer.accountOwner === currentUser.uid
      );
    }
    
    // Derefter filtrer efter status
    return filteredCustomers.filter(customer => customer.status === status);
  };

  const handleDragStart = () => {
    // Tilføj klasse til body under drag
    document.body.classList.add('dragging');
  };

  const handleDragEnd = async (result) => {
    // Fjern dragging klasse
    document.body.classList.remove('dragging');
    
    const { destination, source, draggableId } = result;

    // Drop udenfor eller samme kolonne
    if (!destination || 
        (destination.droppableId === source.droppableId && 
         destination.index === source.index)) {
      return;
    }

    try {
      // Opdater lokalt state først for bedre UX
      setCustomers(prevCustomers => {
        const updatedCustomers = [...prevCustomers];
        const movedCustomer = updatedCustomers.find(c => c.id === draggableId);
        if (movedCustomer) {
          movedCustomer.status = destination.droppableId;
        }
        return updatedCustomers;
      });

      // Derefter opdater i Firebase
      await updateDoc(doc(db, 'customers', draggableId), {
        status: destination.droppableId
      });
    } catch (error) {
      console.error('Fejl ved opdatering af status:', error);
      // Hvis der er fejl, hent data igen
      fetchCustomers();
    }
  };

  return (
    <div className="crm-container">
      <CRMHeader 
        onToggleMenu={onToggleMenu}
        activeFilter={activeFilter}
        onFilterChange={setActiveFilter}
        onAddCustomer={handleAddCustomer}
      />
      <div className="crm-content">
        <DragDropContext 
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <div className="board-container">
            {Object.entries(STATUS_TYPES).map(([status, { label, color, icon }]) => (
              <div key={status} className="board-column">
                <div className="column-header" style={{ borderBottom: `2px solid ${color}` }}>
                  <h3>
                    <span>{icon}</span>
                    {label}
                  </h3>
                  <span className="customer-count">
                    {getCustomersByStatus(status).length}
                  </span>
                </div>
                <Droppable droppableId={status}>
                  {(provided, snapshot) => (
                    <div 
                      className={`column-content ${snapshot.isDraggingOver ? 'dragging-over' : ''}`}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {getCustomersByStatus(status).map((customer, index) => (
                        <Draggable 
                          key={customer.id} 
                          draggableId={customer.id} 
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="draggable-wrapper"
                              style={{
                                ...provided.draggableProps.style,
                                transform: snapshot.isDragging 
                                  ? provided.draggableProps.style?.transform 
                                  : 'translate(0, 0)'
                              }}
                            >
                              <CustomerCard 
                                customer={customer}
                                statusColor={color}
                                onClick={() => {
                                  if (!snapshot.isDragging) {
                                    setSelectedCustomer(customer);
                                    setIsNewCustomer(false);
                                  }
                                }}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            ))}
          </div>
        </DragDropContext>
      </div>
      {selectedCustomer && (
        <CustomerWindow 
          customer={selectedCustomer}
          isNew={isNewCustomer}
          onClose={() => {
            setSelectedCustomer(null);
            setIsNewCustomer(false);
          }}
          onUpdate={fetchCustomers}
        />
      )}
    </div>
  );
}

export default CRMMain;
