import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyB-jtNWKImV35_xzJ-kv4OjM8AcIlQX4cA",
  authDomain: "parla-hub.firebaseapp.com",
  projectId: "parla-hub",
  storageBucket: "parla-hub.firebasestorage.app",
  messagingSenderId: "718833941387",
  appId: "1:718833941387:web:01a4192bcbd98c73690d8e"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };