import React, { useState, useEffect, useCallback, useRef } from 'react';
import { doc, updateDoc, collection, getDocs, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import './styles/customerWindow.css';
import { FaEnvelope, FaPhone, FaMoneyBillWave, FaGlobe, FaClock, FaPlus, FaLinkedin } from 'react-icons/fa';
import Select from 'react-select';

const STATUS_TYPES = {
  lead: { 
    label: 'Lead', 
    color: '#ec4899',
    icon: '🎯',
    description: 'Ny potentiel kunde'
  },
  contacted: { 
    label: 'Kontaktet', 
    color: '#eab308',
    icon: '📞',
    description: 'Første kontakt etableret'
  },
  qualified: { 
    label: 'Kvalificeret', 
    color: '#f97316',
    icon: '✓',
    description: 'Bekræftet potentiale'
  },
  meeting: { 
    label: 'Møde', 
    color: '#2563eb',
    icon: '📅',
    description: 'Møde planlagt/afholdt'
  },
  ongoing: { 
    label: 'Igangværende', 
    color: '#9333ea',
    icon: '⚡',
    description: 'Aktivt samarbejde'
  },
  won: { 
    label: 'Vundet', 
    color: '#22c55e',
    icon: '🏆',
    description: 'Aftale indgået'
  },
  notInterested: { 
    label: 'Ikke interesseret', 
    color: '#6b7280',
    icon: '✕',
    description: 'Ingen interesse'
  }
};

const STATUS_ORDER = [
  'lead',
  'contacted',
  'qualified',
  'meeting',
  'ongoing',
  'won',
  'notInterested'
];

const PRODUCTS = [
  { value: 'website', label: 'Hjemmeside', icon: '🌐' },
  { value: 'video', label: 'Video', icon: '🎥' },
  { value: 'marketing', label: 'Marketing', icon: '📢' },
  { value: 'automation', label: 'Automatisering', icon: '⚡' }
];

const PAYMENT_TYPES = [
  { id: 'oneTime', label: 'Enkeltbeløb', icon: '💰' },
  { id: 'subscription', label: 'Abonnement', icon: '🔄' }
];

const LEAD_SOURCES = [
  { value: 'linkedin', label: 'LinkedIn', icon: '💼' },
  { value: 'leadmagnet', label: 'Leadmagnet', icon: '🧲' },
  { value: 'coldCall', label: 'Cold Call', icon: '📞' },
  { value: 'ads', label: 'Ads', icon: '📣' },
  { value: 'referral', label: 'Referral', icon: '👥' },
  { value: 'website', label: 'Website', icon: '🌐' },
  { value: 'other', label: 'Other', icon: '📌' }
];

// Custom Option Component
const CustomOption = ({ children, ...props }) => {
  const { data } = props;
  return (
    <div
      {...props.innerProps}
      className={`custom-option ${props.isFocused ? 'focused' : ''}`}
    >
      <span className="option-icon">{data.icon}</span>
      {children}
    </div>
  );
};

// Custom Multi-Value Component
const CustomMultiValue = ({ children, ...props }) => {
  const { data } = props;
  return (
    <div className="custom-multi-value">
      <span className="value-icon">{data.icon}</span>
      {children}
    </div>
  );
};

// Tilpas styling konfiguration for react-select
const selectStyles = {
  control: (base, state) => ({
    ...base,
    background: 'rgba(255, 255, 255, 0.03)',
    borderColor: state.isFocused ? '#2563eb' : 'rgba(255, 255, 255, 0.1)',
    boxShadow: state.isFocused ? '0 0 0 1px #2563eb' : 'none',
    '&:hover': {
      borderColor: state.isFocused ? '#2563eb' : 'rgba(255, 255, 255, 0.2)'
    }
  }),
  menu: (base) => ({
    ...base,
    background: '#010510',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)'
  }),
  option: (base, { isFocused, isSelected }) => ({
    ...base,
    background: isSelected 
      ? 'rgba(37, 99, 235, 0.2)' 
      : isFocused 
        ? 'rgba(255, 255, 255, 0.1)' 
        : 'transparent',
    color: '#ffffff',
    cursor: 'pointer',
    '&:active': {
      background: 'rgba(37, 99, 235, 0.3)'
    }
  }),
  multiValue: (base) => ({
    ...base,
    background: 'rgba(37, 99, 235, 0.1)',
    border: '1px solid rgba(37, 99, 235, 0.2)',
    borderRadius: '16px',
    padding: '2px 8px',
    gap: '6px',
    '&:hover': {
      background: 'rgba(37, 99, 235, 0.15)',
      border: '1px solid rgba(37, 99, 235, 0.3)',
    }
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: '#ffffff',
    padding: '2px',
    fontSize: '0.9rem'
  }),
  multiValueRemove: (base) => ({
    ...base,
    display: 'none'
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '6px 8px',
    gap: '4px'
  }),
  clearIndicator: (base) => ({
    ...base,
    display: 'none'
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none'
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: 'rgba(255, 255, 255, 0.4)',
    '&:hover': {
      color: 'rgba(255, 255, 255, 0.8)'
    }
  }),
  input: (base) => ({
    ...base,
    color: '#ffffff'
  }),
  placeholder: (base) => ({
    ...base,
    color: 'rgba(255, 255, 255, 0.3)'
  }),
  singleValue: (base) => ({
    ...base,
    color: '#ffffff'
  })
};

const formatCurrency = (value) => {
  if (!value) return '0,00';
  
  // Konverter til nummer og afrund til 2 decimaler
  const number = Number(value);
  
  // Formater med tusindtalsseparator og decimaler
  return number.toLocaleString('da-DK', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }) + ',-';
};

function CustomerWindow({ customer, isNew, onClose, onUpdate }) {
  const [formData, setFormData] = useState({
    ...customer,
    status: customer.status || 'lead',
    products: customer.products || [],
    paymentType: customer.paymentType || '',
    leadSources: customer.leadSources || [],
    estimatedValue: customer.estimatedValue || '',
    websiteUrl: customer.websiteUrl || '',
    linkedinUrl: customer.linkedinUrl || '',
    updates: customer.updates || []
  });
  const [users, setUsers] = useState([]);
  const [saving, setSaving] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const windowRef = useRef(null);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    setFormData({
      ...customer,
      status: customer.status || 'lead',
      products: customer.products || [],
      paymentType: customer.paymentType || '',
      leadSources: customer.leadSources || [],
      estimatedValue: customer.estimatedValue || '',
      websiteUrl: customer.websiteUrl || '',
      linkedinUrl: customer.linkedinUrl || '',
      updates: customer.updates || []
    });
    fetchUsers();
  }, [customer]);

  const fetchUsers = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'users'));
      const usersList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setUsers(usersList);
    } catch (error) {
      console.error('Fejl ved hentning af brugere:', error);
    }
  };

  const debouncedSave = useCallback(
    debounce(async (data) => {
      try {
        setSaving(true);
        await updateDoc(doc(db, 'customers', customer.id), data);
        onUpdate();
        setHasChanges(false);
      } catch (error) {
        console.error('Fejl ved auto-save:', error);
      } finally {
        setSaving(false);
      }
    }, 1000),
    [customer.id, onUpdate]
  );

  useEffect(() => {
    if (!isNew && hasChanges) {
      debouncedSave(formData);
    }
  }, [formData, isNew, hasChanges, debouncedSave]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (type === 'checkbox') {
      const array = name === 'products' ? 'products' : 'leadSources';
      const newValue = checked 
        ? [...formData[array], value]
        : formData[array].filter(item => item !== value);
      
      if (JSON.stringify(newValue) !== JSON.stringify(formData[array])) {
        setHasChanges(true);
      }
    } else {
      if (formData[name] !== value) {
        setHasChanges(true);
      }
    }
    
    if (type === 'checkbox') {
      const array = name === 'products' ? 'products' : 'leadSources';
      setFormData(prev => ({
        ...prev,
        [array]: checked 
          ? [...prev[array], value]
          : prev[array].filter(item => item !== value)
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleStatusChange = async (newStatus) => {
    try {
      setSaving(true);
      setFormData(prev => ({
        ...prev,
        status: newStatus
      }));
      
      await updateDoc(doc(db, 'customers', customer.id), {
        status: newStatus
      });
      
      onUpdate();
    } catch (error) {
      console.error('Fejl ved statusændring:', error);
    } finally {
      setSaving(false);
    }
  };

  const handleMultiSelect = (e, array) => {
    const value = e.target.value;
    setFormData(prev => ({
      ...prev,
      [array]: prev[array].includes(value)
        ? prev[array].filter(item => item !== value)
        : [...prev[array], value]
    }));
  };

  const handleAddUpdate = () => {
    const newUpdate = {
      id: Date.now(),
      text: '',
      timestamp: new Date().toISOString()
    };
    
    setFormData(prev => ({
      ...prev,
      updates: [newUpdate, ...prev.updates]
    }));
  };

  const handleUpdateChange = (id, text) => {
    setFormData(prev => ({
      ...prev,
      updates: prev.updates.map(update => 
        update.id === id ? { ...update, text } : update
      )
    }));
  };

  const handleSaveUpdate = (id) => {
    setFormData(prev => ({
      ...prev,
      updates: prev.updates.map(update => 
        update.id === id ? { ...update, isEditing: false } : update
      )
    }));
  };

  const handleDeleteUpdate = (id) => {
    setFormData(prev => ({
      ...prev,
      updates: prev.updates.filter(update => update.id !== id)
    }));
  };

  const handleDeleteCustomer = async () => {
    try {
      await deleteDoc(doc(db, 'customers', customer.id));
      onUpdate();
      onClose();
    } catch (error) {
      console.error('Fejl ved sletning af kunde:', error);
    }
  };

  const handleClickOutside = useCallback((event) => {
    if (windowRef.current && 
        !windowRef.current.contains(event.target) && 
        !event.target.closest('.customer-card')) {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div className="customer-window-wrapper">
      <div className="customer-window" ref={windowRef}>
        <div className="window-header">
          <div className="header-content">
            {isNew ? (
              <h2>Ny kunde</h2>
            ) : (
              <div className="customer-name-edit">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="name-input"
                  placeholder="Indtast kundens navn"
                />
                {!isNew && <div className="customer-id">ID: {customer.id}</div>}
                <div className={`save-status ${saving ? 'saving' : hasChanges ? 'unsaved' : 'saved'}`}>
                  {saving ? 'Gemmer...' : hasChanges ? 'Ændringer ikke gemt' : 'Alle ændringer er gemt'}
                </div>
              </div>
            )}
          </div>
          <button className="close-button" onClick={onClose}>&times;</button>
        </div>

        <form onSubmit={e => e.preventDefault()}>
          {/* Status Funnel Section */}
          <div className="form-section status-section">
            <div className="section-header">
              <h3>Status</h3>
              <div className="section-description">
                Kundens nuværende status i salgspipelinen
              </div>
            </div>
            <div className="status-progress">
              <div className="status-track">
                {STATUS_ORDER.map((statusKey, index) => {
                  const status = STATUS_TYPES[statusKey];
                  const isActive = formData.status === statusKey;
                  const isPassed = STATUS_ORDER.indexOf(formData.status) > index;
                  const isLast = statusKey === 'won' || statusKey === 'notInterested';
                  const showLine = !isLast && statusKey !== 'notInterested';
                  
                  return (
                    <div 
                      key={statusKey}
                      className={`status-step ${isActive ? 'active' : ''} ${isPassed ? 'passed' : ''} ${isLast ? 'last' : ''}`}
                    >
                      <button
                        className="status-button"
                        onClick={() => handleStatusChange(statusKey)}
                        style={{ 
                          '--status-color': status.color
                        }}
                        title={status.description}
                      >
                        <span className="status-icon">{status.icon}</span>
                        <span className="status-label">{status.label}</span>
                      </button>
                      {showLine && <div className="status-line" />}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/* Estimeret værdi Section */}
          <div className="form-section estimated-value-section">
            <div className="section-header">
              <h3>Estimeret værdi</h3>
              <div className="section-description">
                Potentiel værdi af denne kunde
              </div>
            </div>
            <div className="estimated-value-display">
              <div className="input-with-icon value-input-wrapper">
                <FaMoneyBillWave className="input-icon" />
                <input
                  type="text"
                  name="estimatedValue"
                  value={formatCurrency(formData.estimatedValue)}
                  onChange={(e) => {
                    const numbers = e.target.value.replace(/[^0-9]/g, '');
                    const value = numbers ? Number(numbers) / 100 : '';
                    handleChange({
                      target: {
                        name: 'estimatedValue',
                        value: value
                      }
                    });
                  }}
                  placeholder="0,00"
                />
                <span className="currency">DKK inkl. moms</span>
              </div>
            </div>
          </div>

          {/* Main Info Section */}
          <div className="form-section">
            <div className="section-header">
              <h3>Grundlæggende information</h3>
              <div className="section-description">
                Kontaktinformation og primære detaljer
              </div>
            </div>
            <div className="form-group">
              <label>Account Owner</label>
              <select
                name="accountOwner"
                value={formData.accountOwner || ''}
                onChange={handleChange}
                className="select-input"
              >
                <option value="">Vælg account owner</option>
                {users.map(user => (
                  <option key={user.id} value={user.id}>
                    {user.name || user.email}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-grid">
              <div className="form-group">
                <label>Email</label>
                <div className="input-with-icon">
                  <FaEnvelope className="input-icon" />
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="email@example.com"
                  />
                </div>
              </div>

              <div className="form-group">
                <label>Telefon</label>
                <div className="input-with-icon">
                  <FaPhone className="input-icon" />
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="+45 12 34 56 78"
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <label>LinkedIn profil</label>
              <div className="input-with-icon">
                <FaLinkedin className="input-icon" />
                <input
                  type="url"
                  name="linkedinUrl"
                  value={formData.linkedinUrl}
                  onChange={handleChange}
                  placeholder="https://linkedin.com/in/..."
                />
              </div>
            </div>
          </div>

          {/* Business Details Section */}
          <div className="form-section">
            <div className="section-header">
              <h3>Forretningsdetaljer</h3>
              <div className="section-description">
                Økonomiske og forretningsmæssige oplysninger
              </div>
            </div>
            <div className="form-grid">
              <div className="form-group">
                <label>Hjemmeside URL</label>
                <div className="input-with-icon">
                  <FaGlobe className="input-icon" />
                  <input
                    type="url"
                    name="websiteUrl"
                    value={formData.websiteUrl}
                    onChange={handleChange}
                    placeholder="https://"
                  />
                </div>
              </div>
            </div>

            <div className="form-grid">
              <div className="form-group">
                <label>Produkter</label>
                <Select
                  isMulti
                  name="products"
                  options={PRODUCTS}
                  value={PRODUCTS.filter(p => formData.products.includes(p.value))}
                  onChange={(selected) => {
                    setFormData(prev => ({
                      ...prev,
                      products: selected ? selected.map(item => item.value) : []
                    }));
                  }}
                  components={{
                    Option: CustomOption,
                    MultiValue: CustomMultiValue
                  }}
                  styles={selectStyles}
                  placeholder="Vælg produkter..."
                  noOptionsMessage={() => "Ingen produkter fundet"}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  isClearable={true}
                  backspaceRemovesValue={true}
                  isSearchable={true}
                  menuPlacement="auto"
                  maxMenuHeight={200}
                />
              </div>

              <div className="form-group">
                <label>Betalingstype</label>
                <select
                  name="paymentType"
                  value={formData.paymentType}
                  onChange={handleChange}
                  className="select-input"
                >
                  <option value="">Vælg betalingstype</option>
                  {PAYMENT_TYPES.map(type => (
                    <option key={type.id} value={type.id}>
                      {type.icon} {type.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* Lead Sources & Notes Section */}
          <div className="form-section">
            <div className="section-header">
              <h3>Lead kilder & Noter</h3>
              <div className="section-description">
                Hvor kommer kunden fra og andre relevante noter
              </div>
            </div>
            <div className="form-group">
              <label>Lead Sources</label>
              <Select
                isMulti
                name="leadSources"
                options={LEAD_SOURCES}
                value={LEAD_SOURCES.filter(s => formData.leadSources.includes(s.value))}
                onChange={(selected) => {
                  setFormData(prev => ({
                    ...prev,
                    leadSources: selected ? selected.map(item => item.value) : []
                  }));
                }}
                components={{
                  Option: CustomOption,
                  MultiValue: CustomMultiValue
                }}
                styles={selectStyles}
                placeholder="Vælg lead sources..."
                noOptionsMessage={() => "Ingen lead sources fundet"}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                isClearable={true}
                backspaceRemovesValue={true}
                isSearchable={true}
                menuPlacement="auto"
                maxMenuHeight={200}
              />
            </div>

            <div className="form-group">
              <label>Noter</label>
              <textarea
                name="notes"
                value={formData.notes}
                onChange={handleChange}
                rows="4"
                placeholder="Tilføj noter om kunden..."
              />
            </div>
          </div>

          {/* Updates Section */}
          <div className="form-section updates-section">
            <div className="section-header">
              <h3>Opdateringer</h3>
              <div className="section-description">
                Tilføj opdateringer og noter om kundens forløb
              </div>
            </div>

            <button 
              type="button" 
              className="add-update-button"
              onClick={handleAddUpdate}
            >
              <FaPlus />
              Tilføj opdatering
            </button>

            <div className="updates-list">
              {formData.updates.map(update => (
                <div key={update.id} className="update-item">
                  <div className="update-timestamp">
                    <FaClock />
                    {new Date(update.timestamp).toLocaleString('da-DK')}
                  </div>
                  <div className="update-content-wrapper">
                    <textarea
                      value={update.text}
                      onChange={(e) => handleUpdateChange(update.id, e.target.value)}
                      placeholder="Skriv din opdatering her..."
                      className="update-textarea"
                    />
                    <button
                      type="button"
                      className="delete-update-button"
                      onClick={() => handleDeleteUpdate(update.id)}
                      title="Slet opdatering"
                    >
                      &times;
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </form>

        {/* Delete Confirmation Dialog */}
        {showDeleteConfirm && (
          <div className="delete-confirm-overlay">
            <div className="delete-confirm-dialog">
              <h3>Slet kunde</h3>
              <p>Er du sikker på, at du vil slette denne kunde? Dette kan ikke fortrydes.</p>
              <div className="delete-confirm-actions">
                <button 
                  className="cancel-button"
                  onClick={() => setShowDeleteConfirm(false)}
                >
                  Annuller
                </button>
                <button 
                  className="delete-confirm-button"
                  onClick={handleDeleteCustomer}
                >
                  Ja, slet kunde
                </button>
              </div>
            </div>
          </div>
        )}

        {!isNew && (
          <div className="form-section danger-zone">
            <div className="section-header">
              <h3>Farlig zone</h3>
              <div className="section-description">
                Permanente handlinger der ikke kan fortrydes
              </div>
            </div>
            <button 
              type="button" 
              className="delete-customer-button"
              onClick={() => setShowDeleteConfirm(true)}
            >
              Slet kunde
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export default CustomerWindow;
